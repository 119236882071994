<template>
	<v-navigation-drawer
			floating
			:stateless="$vuetify.breakpoint.width >= 600"
			color="transparent"
			v-model="drawer"
			app
			overlay-opacity="0.6"
			:width="240"
			right
	>
		<div class="fill-height cell">
			<div class="sub-title-2 d-flex px-3" style="height: 40px; align-items: center">{{ $tc('global.name.widget', 2) }}</div>
			<Select v-model="widget_category" :label="$tc('global.name.category', 2)" :items="widget_categories" itemValue="name" class="ma-3" />

			<v-divider class="mx-4" />

			<draggable
					v-model="widgets2display"
					class="pa-3"
					style="height: calc(100% - 179px); overflow: hidden auto"
					:group="{ name: 'widgets', put: true }"
					ghost-class="ghost"
					:sort="false"
			>
				<transition-group name="slide">
					<div v-for="item in widgets2display" :key="item.id" class="pb-3 rounded-lg" style="cursor: grab">
						<v-hover v-slot:default="{ hover }">
							<v-row  no-gutters class="flex-nowrap mx-0 paragraph pa-2 rounded-lg text-truncate transition-cubic elevation-2" :class="hover ? 'cell3':'cell2'" align="center">
								<v-col cols="auto" class="pr-3">
									<v-icon size="16">{{ item.icon }}</v-icon>
								</v-col>
								<v-col class="pr-8">
									<div class="text-truncate">{{ item.display_name | firstCapital }}</div>
								</v-col>
							</v-row>
						</v-hover>
					</div>
				</transition-group>
			</draggable>

			<v-divider class="mx-4" />

			<div class="pa-3">
				<div class="mb-2 paragraph">{{ $t('global.action.search_report') }}</div>
				<TextField v-model="search" :placeholder="$t('global.action.search')" appendIcon="mdi-magnify" :iconClick="() => {}" />
			</div>
		</div>
	</v-navigation-drawer>
</template>

<script>
	export default {
		name: "SideBarRight",
		props: ['pevents'],
		components: {
			Button: () => import('@/components/ui/Button.vue'),
			TextField: () => import('@/components/fields/TextField.vue'),
			draggable: () => import('vuedraggable'),
			Select: () => import('@/components/fields/Select.vue')
		},
		data(){
			return {
				search: '',
				widget_category: {
					display_name: this.$tc('global.name.all', 2),
					name: 'all'
				},
				base_widgets: [],
				widgets2display: [],
				widget_categories: []
			}
		},
		computed: {
			widgets() {
				return this.$store.getters['profile/widgets']
			},
			drawer: {
				get(){
					return this.$store.getters['dashboard/drawer_widgets']
				},
				set(val) {
					this.$store.commit('dashboard/CHANGE_DRAWER_WIDGETS_VAL', val)
				}
			}
		},
		watch: {
			widget_category(val){
				if(val.name !== 'all'){
					this.widgets2display = this.base_widgets.filter(e => e.category.id === val.id)
				}
				else {
					this.widgets2display = this.base_widgets
				}
			}
		},
		methods: {
			fetchCategories() {
				this.$wsc.getList('report/category', {}, success => {
					this.widget_categories = success
					this.widget_categories.unshift({
						display_name: this.$tc('global.name.all', 2),
						name: 'all'
					})
				}, fail => {

				})
			},
			fetchWidgets() {
				this.$wsc.getList('widgets', {}, success => {
					let mapped = success.map(e => {return {id: e.id, display_name: e.display_name, type: e.type, category: e.category, icon: e.icon}})
					let exclude = []

					this.widgets.forEach(r => {
						r.items.forEach(w => {
							if(mapped.find(e => e.id === w.id)){
								exclude.push(w)
							}
						})
					})

					let res = []

					mapped.forEach(e => {
						if(!exclude.find(f => e.id === f.id)){
							res.push(e)
						}
					})

					this.pevents.notify('base-widget', mapped)
					this.base_widgets = res
					this.widgets2display = [...res]
				}, fail => {

				})
			},
			setUpHooks(){
				this.pevents.wait('widget2delete', (val) => {
					if(val.length > 0){
						val.forEach(e => {
							if(e.category.id === this.widget_category.id || this.widget_category.name === 'all'){
								this.widgets2display.unshift(e)
							}

							this.base_widgets.unshift(e)
						})
					}
				})

				this.pevents.wait('update-app', () => {
					this.fetchCategories()
					this.fetchWidgets()
				})
			}
		},
		created() {
			this.fetchCategories()
			this.fetchWidgets()
			this.setUpHooks()
		}
	}
</script>

<style scoped>
	.ghost {

	}

	.ghost > *:first-child {
		box-shadow: 0 0 0 2px rgba(0, 120, 255, 0.5) inset;
		background-color: rgba(0, 120, 255, 0.5) !important;
		min-height: 35.5px;
		height: calc(100% + 12px);
	}

	.ghost > * > * {
		display: none;
	}
</style>
